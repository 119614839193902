import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  MatRippleModule,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabNav, MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadge } from '@angular/material/badge';
import {
  NgxMatDatetimepicker,
  NgxMatDatepickerActions,
  NgxMatDatepickerApply,
  NgxMatDatepickerCancel,
  NgxMatDatepickerClear,
  NgxMatDatepickerInput,
} from '@ngxmc/datetime-picker';
import { CustomDateAdapter } from './CustomDate';
import { Platform } from '@angular/cdk/platform';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { MatListModule } from '@angular/material/list';
import { NgxMatInputTelComponent } from 'ngx-mat-input-tel';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDateFnsModule, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';

@NgModule({
  declarations: [],
  exports: [
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSnackBarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    DragDropModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDividerModule,
    ClipboardModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatInputTelComponent,
    MatAutocompleteModule,
    MatBadge,
    NgxMatDatetimepicker,
    NgxMatDatepickerActions,
    NgxMatDatepickerApply,
    NgxMatDatepickerCancel,
    NgxMatDatepickerClear,
    NgxMatDatepickerInput,
    MatDateFnsModule,
    MatSliderModule,
    MatListModule,
    MatPaginatorModule,
    MatStepperModule,
    MatRippleModule,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatChipsModule,
    MatFormFieldModule,
    MatMenuModule,
    MatSnackBarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTabsModule,
    MatDialogModule,
    DragDropModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDividerModule,
    ClipboardModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatTableModule,
    MatNativeDateModule,
    MatDatepickerModule,
    NgxMatInputTelComponent,
    MatAutocompleteModule,
    MatBadge,
    NgxMatDatetimepicker,
    NgxMatDatepickerActions,
    NgxMatDatepickerApply,
    NgxMatDatepickerCancel,
    NgxMatDatepickerClear,
    NgxMatDatepickerInput,
    MatSliderModule,
    MatDateFnsModule,
    MatListModule,
    MatPaginatorModule,
    MatStepperModule,
    MatRippleModule,
  ],
  providers: [
    {
      provide: MatTabNav,
      useClass: MatTabNav,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})
export class MaterialModule {}
