import { Injectable, Optional, Inject } from '@angular/core';
import { isToday, isTomorrow, format, getMonth, getDate, getYear, Locale } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { DateFnsAdapter } from '@angular/material-date-fns-adapter';

@Injectable()
export class CustomDateAdapter extends DateFnsAdapter {
  constructor(@Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: any, platform: Platform) {
    super();
    super.setLocale(enUS);
  }

  public override format(date: Date, displayFormat: any): string {
    if (!this.isValid(date)) {
      throw Error('Cannot format invalid date.');
    }

    console.log('displayFormat', displayFormat);
    if (typeof displayFormat === 'string' && displayFormat.startsWith('P')) {
      let formattingText = `h:mmaaaaa'm' M/dd/yyyy`;
      if (isToday(date)) {
        formattingText = `h:mmaaaaa'm' 'Today'`;
      } else if (isTomorrow(date)) {
        formattingText = `h:mmaaaaa'm' 'Tomorrow'`;
      }
      return format(date, formattingText);
    }

    return super.format(date, displayFormat);
  }
}
